<script setup lang="ts">
import request, { AxiosError } from 'axios'
import type {
  CreatorHomeTabType,
  NovaBoxCreatorTopHeaderEmits,
  NovaBoxCreatorTopHeaderProps,
} from './NovaBoxCreatorTopHeader.types'
import {
  type CreatorProfileByCreatorLinkPayload,
  type CreatorProfileEditPayload,
  BlockingTarget,
  LayerType,
} from '@store'
import type { TabData } from '@components/NovaTab/NovaTab.types'
import type { HomeActionProps } from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'
import { NovaModalDialog } from '#components'
import { useCheckEmpty } from '@composables/useCheckEmpty'
const emit = defineEmits<NovaBoxCreatorTopHeaderEmits>()
const props = withDefaults(defineProps<NovaBoxCreatorTopHeaderProps>(), {
  isShowTabMenu: true,
})
const { tabs, profile } = toRefs(props)
const { gtEvent } = useGoogleTag()
const creatorStore = useCreatorStore()
const commStore = useCommStore()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const { userStore, showSignInDialog } = useMembershipProcess()
const { show: modalShow, hide: modalHide } = useModal()
const isLoading = ref(false)
const isOpen = ref(true)
const { t } = useI18n()
const state = reactive({
  nameInput: creatorStore.profile?.userNcnm || '',
  textInput: creatorStore.profile?.proflDc || '',
  isNickname: false,
  isText: false,
})
// state.nameInput
const creatorMyTaps = tabs.value.filter(
  (item) =>
    !(
      userStore.user?.userSn !== profile.value?.userSn &&
      (item.id === 'activity' ||
        item.id === 'reward' ||
        item.id === 'statistics' ||
        item.id === 'donation' ||
        item.id === 'collections')
    )
)
const isEqualUser = computed(
  () =>
    userStore.isSignIn &&
    creatorStore.profile?.userSn === userStore.user?.userSn
)
const { checkPermission, requestPermission } = useAppPermission()
const creatorProfileDescription = computed(() =>
  (creatorStore.profile?.proflDc || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
)
const homeActionParams = computed(() => {
  const profile = creatorStore.profile
  const params: HomeActionProps | null = profile
    ? {
        target: BlockingTarget.CREATOR,
        userSn: profile.userSn,
        userNcnm: profile.userNcnm,
        isDonation: profile.prmbrshSbscrbAt === 'Y',
        blockingAt: profile.blockingAt,
        followerAt: profile.followerAt,
      }
    : null
  return params
})
const onDeleteProfile = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 이미지 삭제 버튼 클릭',
    eventLabel: 'close-compact',
    eventSlot: '프로필 이미지 삭제 아이콘',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  await uploadImageDialogModal(
    t('mypage.profile.dialog.title'),
    t('mypage.profile.dialog.initImage')
  )
}
const onModifyProfile = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'profileImage',
      })
      if (!accessAble) return
    }
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 이미지 변경 버튼 클릭',
    eventLabel: 'setting',
    eventSlot: '프로필 이미지 변경 버튼',
    eventI18nAddr: 'setting',
    eventComponent: 'Button',
  })
  try {
    const { formData } = await useGetFiles({
      accept: 'image/*',
      multiple: false,
      formDataName: 'profileImg',
    })
    const payload: CreatorProfileByCreatorLinkPayload = {
      creatorLink: profile.value.creatorLink,
    }
    await creatorStore.createProfilePhoto(formData, payload)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '프로필 파일 업로드',
      eventLabel: 'setting',
      eventSlot: '프로필 이미지 파일 업로드',
      eventI18nAddr: '',
      eventComponent: 'Button',
      fileName: mypageStore.profile!.userProflUrl,
    })
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 이미지 변경',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
  } catch (err) {
    if (request.isAxiosError(err) && err.response) {
      switch (err.response.status) {
        case 2005:
          useToast(t('mypage.profile.toastMessage.imgError2005'))
          break
        default:
          useToast(t('mypage.profile.toastMessage.imgError'))
          break
      }
    }
  }
}

const onChangeTab = (tabId: TabData<CreatorHomeTabType>) => {
  emit('onChangeTab', tabId.id)
}

const onCancelName = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 취소 버튼 클릭',
    eventLabel: t('mypage.profile.button.cancel'),
    eventSlot: '닉네임 변경 취소',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.cancel'),
    eventComponent: 'Button',
  })
  state.nameInput = ''
  state.isNickname = false
}

const onSaveName = async () => {
  const isUnavailableNickname = useCheckUrlSpecialCharacter(state.nameInput)
  const isEmpty = useCheckEmpty(state.nameInput)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 > 저장 버튼 클릭',
    eventLabel: t('mypage.profile.button.save'),
    eventSlot: '닉네임 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
    eventComponent: 'Button',
  })
  if (isEmpty) {
    useToast(t('mypage.profile.placeholder.nickname'))
    state.nameInput = ''
    return
  }
  if (isUnavailableNickname) {
    useToast(t('mypage.profile.toastMessage.unavailableNickname'))
    return
  }
  try {
    const payload: CreatorProfileEditPayload = {
      proflDc: mypageStore.profile!.proflDc,
      userNcnm: state.nameInput,
      creatorLink: profile.value.creatorLink,
    }
    await creatorStore.editProfile(payload)
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '닉네임 변경',
      eventLabel: t('mypage.profile.button.save'),
      eventSlot: '닉네임 변경',
      eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
      eventComponent: 'Button',
      userNcnm: payload.userNcnm,
    })
    state.isNickname = false
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 2010:
          await showDialogModal(
            t('mypage.profile.dialog.title'),
            t('mypage.profile.dialog.doubleNickname')
          )
          state.nameInput = ''
          break
        default:
          useToast(t('commonError.network.message'))
          break
      }
    }
  }
}

const onSaveText = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 저장 버튼 클릭',
    eventLabel: t('mypage.profile.button.save'),
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
    eventComponent: 'Button',
  })
  try {
    const payload: CreatorProfileEditPayload = {
      proflDc: state.textInput,
      userNcnm: mypageStore.profile!.userNcnm,
      creatorLink: profile.value.creatorLink,
    }
    await creatorStore.editProfile(payload)
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 내용 변경',
      eventLabel: t('mypage.profile.button.save'),
      eventSlot: '프로필 내용 변경',
      eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
      eventComponent: 'Button',
    })
    state.isText = false
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        default:
          useToast(t('commonError.network.message'))
          break
      }
    }
  }
}

const onCancelText = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 취소 버튼 클릭',
    eventLabel: t('mypage.profile.button.cancel'),
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.cancel'),
    eventComponent: 'Button',
  })
  state.textInput = ''
  state.isText = false
}

const showDialogModal = (title: string, content: string) => {
  return modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
      ],
    },
    slots: {
      title,
      content,
    },
  })
}

// gnb 사용자 정보 패널 열기
// const handleOnOpenAccount = () => {
//   useEvent('gnb:account')
// }

const handleOnChangeUserNcnm = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 버튼 클릭',
    eventLabel: 'write',
    eventSlot: '닉네임 변경',
    eventI18nAddr: 'write',
    eventComponent: 'Button',
  })
  state.isNickname = true
  state.nameInput = creatorStore.profile?.userNcnm || ''
}

const handleOnChangeUserDescription = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 버튼 클릭',
    eventLabel: 'write',
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: 'write',
    eventComponent: 'Button',
  })
  state.isText = true
  state.textInput = creatorStore.profile?.proflDc || ''
}

const handleOnToggleFollow = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  const doToggleFollow = async (
    toggleAction: () => Promise<void>,
    type: 'follow' | 'unfollow'
  ) => {
    try {
      await toggleAction()

      if (creatorStore.profile) {
        switch (type) {
          case 'follow':
            creatorStore.profile.followerCount += 1
            break
          case 'unfollow':
            creatorStore.profile.followerCount -= 1
            break
          default:
            break
        }
      }
    } catch {}
  }
  creatorStore.profile?.followerYn === 'N'
    ? await doToggleFollow(() => handleOnFollow(), 'follow')
    : await doToggleFollow(() => handleOnUnFollow(), 'unfollow')
}

const handleOnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    await commStore.reqFollow({
      flwUserSn: creatorStore.profile!.userSn,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '팔로우',
      eventLabel: t('follow'),
      eventSlot: '팔로우 하기',
      eventI18nAddr: useKoreanTranslation('follow'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

const handleOnUnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    await commStore.reqUnfollow({
      flwUserSn: creatorStore.profile!.userSn,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '언팔로우',
      eventLabel: t('following'),
      eventSlot: '언팔로우 하기',
      eventI18nAddr: useKoreanTranslation('following'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 기부하기
const handleOnDonate = async () => {
  await useDonate({
    userSn: creatorStore.profile!.userSn,
    userNcnm: creatorStore.profile!.userNcnm,
  })
}

// 프리미엄 구독서비스 신청하기
/* const handleOnSubscribe = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  if (creatorStore.profile?.prmbrshSbscrbAt === 'Y') {
    return
  }

  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '노바+ 후원하기 버튼 클릭',
    eventLabel: t('subscribe.actions.support', {
      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
    }),
    eventSlot: '후원하기',
    eventI18nAddr: useKoreanTranslation('subscribe.actions.support', {
      subscribeGrade: useKoreanTranslation('subscribe.subscribeGrade.novaPlus'),
    }),
    eventComponent: 'Button',
    subscribeUserSn: creatorStore.profile?.userSn,
  })
  await useNavigations({
    external: false,
    url: `/creator/subscribe/support/${creatorStore.profile!.userSn}`,
  })
} */

const uploadImageDialogModal = async (title: string, content: string) => {
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_UPLOAD_IMAGE,
      btns: [
        {
          label: t('cancel'),
          theme: 'transparent',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '프로필 이미지 삭제 > 취소 버튼 클릭',
              eventLabel: t('cancel'),
              eventSlot: '프로필 이미지 삭제 모달',
              eventI18nAddr: useKoreanTranslation('cancel'),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_UPLOAD_IMAGE)
            return false
          },
        },
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            const payload: CreatorProfileByCreatorLinkPayload = {
              creatorLink: profile.value.creatorLink,
            }
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '프로필 이미지 삭제 > 확인 버튼 클릭',
              eventLabel: t('mypage.profile.button.save'),
              eventSlot: '프로필 삭제 모달',
              eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_UPLOAD_IMAGE)
            try {
              await creatorStore.deleteProfilePhoto(payload)
              await mypageStore.fetchProfile()
            } catch (err: any) {
              const errorData = err.response
              if (errorData.status === 2004) {
                await showDialogModal(
                  t('mypage.profile.dialog.title'),
                  t('mypage.profile.dialog.noImage')
                )
                state.nameInput = ''
              }
            }
          },
        },
      ],
    },
    slots: {
      title,
      content,
    },
  })
}

const openAccountSetting = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '마이 홈 > 프로필 수정 버튼 클릭',
    eventLabel: 'more-vertical',
    eventSlot: '프로필 수정 열기',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  layoutStore['layer:open'](LayerType.USER_PROFILE_EDIT_PANEL)
}
onUnmounted(() => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
})

// ------------------------
const profileData = reactive({
  userSn: creatorStore.profile!.userSn,
  followerCount: creatorStore.profile?.followerCount,
  followingCount: creatorStore.profile?.followingCount,
  coins:
    userStore.user?.userSn === creatorStore.profile?.userSn
      ? creatorStore.profile?.rwardSmLM
      : undefined,
  profile: creatorStore.profile,
})
</script>

<template>
  <div :class="['top-section', { off: !isOpen }]">
    <NovaImageContainer
      :key="creatorStore.profile?.backgroundImage"
      :image-url="creatorStore.profile?.backgroundImage"
      :ratio="'5:1'"
      :empty-type="'none'"
      class="top-section-bg"
    />

    <div class="profile-wrap">
      <div class="option-tool">
        <span v-if="!isOpen" class="title">
          {{ t('creatorMyPage.profile.title') }}
        </span>
      </div>

      <div v-if="isOpen" class="profile-area">
        <div class="profile-box">
          <NovaButtonIcon
            v-if="isEqualUser && mypageStore.profile?.userAvataUseAt === 'N'"
            :theme="'transparent'"
            :icon="{ type: 'outline', name: 'close-compact' }"
            :size="24"
            class="btn-delete"
            @click="onDeleteProfile"
          />

          <NovaBoxCreatorPortrait
            v-if="creatorStore.profile"
            :block-status="blockStatus"
            :profile="creatorStore.profile"
          />

          <NovaCreatorGradeBadge
            :creator-grade="creatorStore.profile!.crtrSpclGradCode"
            :creator-organization="creatorStore.profile!.crtrOrgnztTyCode"
            :size="'lg'"
            class="creator-badge"
          />

          <div v-if="isEqualUser" class="btn-box">
            <NovaButtonIcon
              :theme="'square-bg'"
              :icon="{ type: 'outline', name: 'setting' }"
              :size="24"
              :fill-icon="true"
              class="btn-edit"
              @click="onModifyProfile"
            />
          </div>
        </div>

        <div class="nickname-box">
          <span v-if="!state.isNickname">
            <span class="text">{{ creatorStore.profile?.userNcnm }}</span>

            <NovaButtonIcon
              v-if="isEqualUser"
              :theme="'transparent'"
              :icon="{ type: 'outline', name: 'write' }"
              :size="14"
              class="btn-edit"
              @click="handleOnChangeUserNcnm"
            />
          </span>

          <span v-if="state.isNickname" class="edit-box">
            <NovaInput
              v-model="state.nameInput"
              class="nickname"
              :placeholder="t('mypage.profile.placeholder.nickname')"
              :max-length="25"
            />

            <span class="action-box">
              <NovaButtonText
                :label="t('mypage.profile.button.cancel')"
                :theme="'secondary-gray'"
                :size="32"
                class="btn-action"
                @click="onCancelName"
              />

              <NovaButtonText
                :label="t('mypage.profile.button.save')"
                :theme="'primary-blue-light'"
                :size="32"
                :disabled="state.nameInput.length <= 0"
                class="btn-action"
                @click="onSaveName"
              />
            </span>
          </span>
        </div>

        <div class="content-box">
          <span v-if="!state.isText">
            <span v-dompurify-html="creatorProfileDescription" class="text" />

            <NovaButtonIcon
              v-if="isEqualUser"
              :theme="'transparent'"
              :icon="{ type: 'outline', name: 'write' }"
              :size="14"
              class="btn-edit"
              @click="handleOnChangeUserDescription"
            />
          </span>

          <span v-if="state.isText" class="edit-box">
            <NovaInput
              v-if="state.isText"
              v-model="state.textInput"
              :placeholder="t('mypage.profile.placeholder.content')"
              :max-length="30"
            />

            <span class="action-box">
              <NovaButtonText
                :label="t('mypage.profile.button.cancel')"
                :theme="'secondary-gray'"
                :size="32"
                class="btn-action"
                @click="onCancelText"
              />

              <NovaButtonText
                :label="t('mypage.profile.button.save')"
                :theme="'primary-blue-light'"
                :size="32"
                class="btn-action"
                @click="onSaveText"
              />
            </span>
          </span>
        </div>

        <ClientOnly>
          <div v-if="!isShowBlockMessage" class="subscribe-box">
            <NovaBoxUserUnitSummery
              :user-sn="profileData?.userSn"
              :followers="profile?.followerCount || 0"
              :followings="profile?.followingCount || 0"
              :coins="profileData?.coins"
              :is-equal-user="isEqualUser"
              :creator="profileData?.profile"
            />

            <div
              v-if="userStore.user?.userSn !== creatorStore.profile?.userSn"
              class="btns-box"
            >
              <NovaButtonText
                :on="creatorStore.profile?.followerYn === 'Y'"
                :label="
                  t(
                    creatorStore.profile?.followerYn === 'N'
                      ? 'follow'
                      : 'following'
                  )
                "
                :responsive-size="{ desktop: 40, mobile: 32 }"
                :loading="isLoading"
                :class="
                  creatorStore.profile?.followerYn === 'Y'
                    ? 'btn-creator-unfollow'
                    : 'btn-creator-follow'
                "
                @click.stop="handleOnToggleFollow"
              />
              <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
              <!--              <NovaButtonText
                v-if="
                  !(
                    userStore.user &&
                    userStore.user.userSn === creatorStore.profile?.userSn
                  ) && !(appStore.isApp || appStore.envMode === 'production')
                "
                :label="
                  t(
                    `subscribe.actions.${
                      creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                        ? 'supporting'
                        : 'support'
                    }`,
                    {
                      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                    }
                  )
                "
                :responsive-size="{ desktop: 40, mobile: 32 }"
                :class="
                  creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                    ? 'btn-creator-unfollow'
                    : 'btn-creator-follow'
                "
                @click.stop="handleOnSubscribe"
              />-->

              <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
              <!-- 노바+ 후원하기가 아닌 일반 후원하기 -->
              <!-- 원래 조건: v-if="!appStore.isApp && appStore.envMode === 'production'" -->
              <NovaButtonText
                v-if="!appStore.isApp"
                :label="$t('donate')"
                :responsive-size="{ desktop: 40, mobile: 32 }"
                :class="'btn-creator-follow'"
                @click.stop="handleOnDonate"
              />
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>

    <NovaBoxHomeAction
      v-if="!isEqualUser && homeActionParams"
      :source="homeActionParams"
      class="home-action"
    />

    <!-- 사용자 차단 안내 -->
    <slot name="blockContents" />

    <div v-if="isShowTabMenu" class="menu-box">
      <NovaTab
        :tab-value="creatorMyTaps"
        :init-tab="initTab"
        :align="'center'"
        :horizon-padding="20"
        @on-change-tab="onChangeTab"
      />
    </div>

    <NovaBadgeLiveAction
      v-if="isEqualUser"
      :available-click="true"
      :on-air="creatorStore.profile?.liveOnAirAt === 'Y'"
      class="badge-live"
    />

    <div v-if="isEqualUser" class="header-floating-button">
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'more-vertical' }"
        :size="20"
        @click="openAccountSetting"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-section {
  flex-shrink: 0;
  position: relative;
  padding: 13px 20px 0;
  border-radius: 16px;
  background-color: $color-white;

  :deep(.top-section-bg) {
    &.is-load-success {
      background: inherit;
    }
  }

  .top-section-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 245px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(
      97.77deg,
      $color-secondary-blue-light-80 -5.67%,
      $color-highlight 126.11%
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 16px 16px 0 0;
    }

    &::after {
      background: hex-to-rgba($color-bg-custom-7, 0.3);
    }
  }

  &.off {
    &::before,
    &::after {
      height: 50px;
    }

    .profile-wrap {
      max-height: 100px;
    }

    .option-tool {
      justify-content: space-between;
    }
  }

  > .badge-live {
    position: absolute;
    top: calc(245px + 19px);
    right: 32px;
    z-index: 11;
    //z-index: 30;

    @include mobile {
      top: calc(245px + 10px);
      right: 18px;
    }
  }
}

.profile-wrap {
  position: relative;
  z-index: 10;
  @include transition(max-height 0.3s);
}

.option-tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;
  height: 20px;

  .title {
    @include text-style($text-heading2-bold);
  }

  .option-button {
    display: flex;
    gap: 8px;
    position: relative;
  }

  .page-setting {
    bottom: auto;
    top: 100%;
    right: 0;
    transform: translateY(10px);
  }
}

.menu-box {
  border-top: 1px solid hex-to-rgba($color-black, 0.06);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

.top-section {
  .profile-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 360px;
    padding-top: 145px;
    padding-bottom: 30px;
    color: $color-text-4;
    opacity: 1;
    @include transition(opacity 0.15s ease-in-out);

    .profile-box {
      position: relative;
      padding: 0 12px;

      .btn-box,
      .btn-delete {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .btn-delete {
        color: $color-white;
        top: 0;
        bottom: auto;
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .input-file {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }

      .creator-badge {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .nickname-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      width: 80%;
      @include mobile {
        width: 100%;
      }
      .text {
        @include text-style($text-heading1-bold);
        color: $color-text-2;
        word-break: break-all;
      }

      .btn-edit {
        margin-left: 8px;
      }
    }

    .content-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      width: 80%;
      @include mobile {
        width: 100%;
      }
      .text {
        display: inline;
        color: $color-text-2;
      }

      .btn-edit {
        margin-left: 8px;
      }
    }
    .edit-box {
      width: 100%;
      .action-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 10px;
      }
    }

    .subscribe-box {
      display: flex;
      flex-direction: column;
      margin: 24px 0 0 0;

      .subscribs {
        display: flex;
        align-items: center;
        gap: 24px;
        p {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          gap: 6px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: $color-99;
          }
          strong {
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: #3f4354;
            &:before {
              display: block;
              content: '';
              width: 110%;
              min-width: 42px;
              height: 13px;
              background: #347ae2;
              opacity: 0.1;
              position: absolute;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
            }
          }
        }
      }
      .btns-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin: 28px 0 0 0;

        .btn-creator-follow {
          min-width: 110px;
          height: 30px;
          padding: 0 20px;
          background: #347ae2;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.3px;
          border-radius: 5px;
        }

        .btn-creator-unfollow {
          min-width: 110px;
          height: 30px;
          padding: 0 20px;
          background: #f4f6f8;
          color: #3f4354;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.3px;
          border-radius: 5px;
        }
      }
    }
  }
  .portrait-container {
    height: 122px;
    width: 122px;
  }

  .header-floating-button {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 12;
  }

  .home-action {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 12;
  }
}

.page-setting {
  z-index: 100;
}
.top-section {
  :deep(.block-wrap) {
    padding-bottom: 20px;
    min-height: auto;
  }
}

@include mobile {
  .menu-box {
    :deep(.tabs .swiper-wrapper) {
      justify-content: flex-start !important;
    }
  }
}
</style>
