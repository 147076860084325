<script setup lang="ts">
import { AxiosError } from 'axios'
import { type RouteParamsGeneric, useRoute as useNativeRoute } from 'vue-router'
import {
  BlockingTarget,
  type CreatorProfile,
  type Post,
  type CreatorNotice,
  type MyCollectionNft,
} from '@store'
import { BlockStatus } from '@composables/useBlockStatus'
import type {
  CreatorHomeTab,
  CreatorHomeTabType,
} from '@components/NovaBoxCreatorTopHeader/NovaBoxCreatorTopHeader.types'
import { type RouteConstant, ROUTES } from '@configs'
const { t, locale, messages } = useI18n()
const route = useNativeRoute()
const creatorStore = useCreatorStore()
const postStore = usePostStore()
const myPageStore = useMyPageStore()

const { blockStatus, blockHeaderMessage, blockContentsMessage } =
  useBlockStatus({
    target: BlockingTarget.CREATOR,
    onShowContents: () => {
      showContents.value = true
    },
  })
const err = ref<AxiosError>()

const showContents = ref<boolean | null>(null)
const showContentsObserver = computed(() => {
  return !(
    blockStatus.value === BlockStatus.MUTUAL_BLOCKING ||
    blockStatus.value === BlockStatus.BLOCKED_BY_OTHER_USER ||
    (blockStatus.value === BlockStatus.BLOCKED_BY_CURRENT_USER &&
      !showContents.value)
  )
})
const tabs = computed(
  () =>
    Object.keys(
      (messages.value[locale.value].creatorMyPage as { tabMenu: Object })
        .tabMenu
    )
      .filter((key) =>
        key === '' ? creatorStore.profile?.prmbrshAt === 'Y' : true
      )
      .map((key) => ({
        id: key,
        name: t(`creatorMyPage.tabMenu.${key}`),
      })) as CreatorHomeTab[]
)
const ROUTES_LOOKUP: { [Key in CreatorHomeTabType]: string } = {
  activity: ROUTES.CREATOR_ACTIVITIES.path,
  donation: ROUTES.CREATOR_DONATION.path,
  guestbook: ROUTES.CREATOR_GUESTBOOK.path,
  notice: ROUTES.CREATOR_NOTICE.path,
  subscribeGrade: ROUTES.CREATOR_NOVAPLUS.path,
  contents: ROUTES.CREATOR_POSTS.path,
  reward: ROUTES.CREATOR_REWARD.path,
  statistics: ROUTES.CREATOR_STATISTICS.path,
  home: ROUTES.CREATOR.path,
  collections: ROUTES.CREATOR_COLLECTIONS.path,
}
const tabId = computed<CreatorHomeTabType>(() => {
  const name = useGetRouteName()
  switch (name) {
    case ROUTES.CREATOR_ACTIVITIES.name:
    case ROUTES.CREATOR_ACTIVITIES_DETAIL.name:
      return 'activity'
    case ROUTES.CREATOR_DONATION.name:
      return 'donation'
    case ROUTES.CREATOR_GUESTBOOK.name:
      return 'guestbook'
    case ROUTES.CREATOR_NOTICE.name:
    case ROUTES.CREATOR_NOTICE_DETAIL.name:
      return 'notice'
    case ROUTES.CREATOR_NOVAPLUS.name:
    case ROUTES.CREATOR_NOVAPLUS_DETAIL.name:
      return 'subscribeGrade'
    case ROUTES.CREATOR_POSTS.name:
    case ROUTES.CREATOR_POSTS_DETAIL.name:
      return 'contents'
    case ROUTES.CREATOR_REWARD.name:
      return 'reward'
    case ROUTES.CREATOR_STATISTICS.name:
      return 'statistics'
    case ROUTES.CREATOR_COLLECTIONS.name:
    case ROUTES.CREATOR_COLLECTIONS_DETAIL.name:
      return 'collections'
    case ROUTES.CREATOR.name:
    case ROUTES.CREATOR_HOME_DETAIL.name:
    default:
      return 'home'
  }
})

const processByRouteName = {
  // 가장 기본이 되는 프로세스
  profile: {
    fetch: async (id: string) => {
      try {
        const result = await creatorStore.fetchProfileLink({
          creatorLink: id.replace('@', ''),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
      }
    },
    seo: ({ profile }: { profile: CreatorProfile }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: profile.userNcnm,
            meta: [
              {
                name: 'description',
                content: profile.proflDc,
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: profile.userNcnm,
            ogDescription: profile.proflDc,
            ogImage: profile.userProflUrl,
          },
        ],
      })
    },
  },
  post: {
    fetch: async (postId: string) => {
      try {
        const result = await postStore.fetchPost({
          cmtyNttSn: Number(postId),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
      }
    },
    seo: ({ profile, post }: { profile: CreatorProfile; post: Post }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: `${post.nttSj} | ${profile.userNcnm}`,
            meta: [
              {
                name: 'description',
                content: useGetMaxThreeParagraphTexts(post.nttCn),
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: `${post.nttSj} | ${profile.userNcnm}`,
            ogDescription: useGetMaxThreeParagraphTexts(post.nttCn),
            ogImage: useGetPostThumbUrl(post),
          },
        ],
      })
    },
  },
  notice: {
    fetch: async ({
      userSn,
      noticeId,
    }: {
      userSn: number
      noticeId: string
    }) => {
      try {
        const result = await creatorStore.fetchCreatorNotice({
          crtrNttSn: Number(noticeId),
          creatorUserSn: userSn,
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
      }
    },
    seo: ({
      profile,
      notice,
    }: {
      profile: CreatorProfile
      notice: CreatorNotice
    }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: `${notice.crtrNttSj} | ${profile.userNcnm}`,
            meta: [
              {
                name: 'description',
                content: useGetMaxThreeParagraphTexts(notice.crtrNttCn),
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: `${notice.crtrNttSj} | ${profile.userNcnm}`,
            ogDescription: useGetMaxThreeParagraphTexts(notice.crtrNttCn),
            ogImage: useGetFirstImageSource(notice.crtrNttCn),
          },
        ],
      })
    },
  },
  collection: {
    fetch: async (collectionId: string) => {
      try {
        const result = await myPageStore.fetchMyCollectionNft({
          randboxSn: Number(collectionId),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
        return Promise.reject(_err)
      }
    },
    seo: ({
      profile,
      collection,
    }: {
      profile: CreatorProfile
      collection: MyCollectionNft
    }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: `${collection.colctNm} | ${profile.userNcnm}`,
            meta: [
              {
                name: 'description',
                content: useGetMaxThreeParagraphTexts(collection.colctDc),
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: `${collection.colctNm} | ${profile.userNcnm}`,
            ogDescription: useGetMaxThreeParagraphTexts(collection.colctDc),
            ogImage: collection.fileUrl,
          },
        ],
      })
    },
  },
}

const setSeoCaseByCase = ({
  profile,
  post,
  notice,
  collection,
  err,
}: {
  profile?: CreatorProfile | null
  post?: Post | null
  notice?: CreatorNotice | null
  collection?: MyCollectionNft | null
  err?: AxiosError
}) => {
  if (err instanceof AxiosError) {
    switch (err.response?.status) {
      case 4010:
        throw showError({
          statusCode: 404,
          statusMessage: `creatorHome ${route.params.id} is not find`,
        })
      default:
        throw showError({
          statusCode: err.response?.status,
          statusMessage: `creatorHome ${route.params.id} is error`,
        })
    }
  }

  if (profile && post) {
    processByRouteName.post.seo({ profile, post })
    return
  }

  if (profile && notice) {
    processByRouteName.notice.seo({ profile, notice })
    return
  }

  if (profile && collection) {
    processByRouteName.collection.seo({ profile, collection })
    return
  }

  if (profile) {
    processByRouteName.profile.seo({ profile })
  }
}

// CSR 에서만 작동 하도록 immediate 옵션은 사용하지 말것
watch(
  () => [route.params, route.name],
  async ([_curParams, _curName], [_prevParams]) => {
    const routeName = useGetRouteName(String(_curName))
    const curParams = _curParams as RouteParamsGeneric
    const prevParams = _prevParams as RouteParamsGeneric

    // 0. 크리에이터 홈 일 경우에만 작동
    const creatorHomeNames = Object.keys(ROUTES)
      .map((key) => ROUTES[key as RouteConstant].name)
      .filter((name) => name.includes(ROUTES.CREATOR.name))

    if (!creatorHomeNames.includes(routeName)) {
      return
    }

    // 1. 유저 홈 사용자 닉네임 변경시 새로운 프로필 조회
    if (curParams.id && curParams.id !== prevParams.id) {
      await processByRouteName.profile.fetch(String(curParams.id))
    }

    // 2. 각 하위 페이지에서 동적 URL을 사용하는 상세 페이지 일때
    // 2-1. 유저 홈 > 대표 포스트 상세 || 유저 홈 > 활동 리스트 > 활동 상세 || 유저 홈 > 포스트 리스트 > 포스트 상세
    if (
      curParams.postId &&
      curParams.postId !== prevParams.postId &&
      (routeName === ROUTES.CREATOR_HOME_DETAIL.name ||
        routeName === ROUTES.CREATOR_ACTIVITIES_DETAIL.name ||
        ROUTES.CREATOR_POSTS_DETAIL)
    ) {
      const post = await processByRouteName.post.fetch(String(curParams.postId))
      setSeoCaseByCase({
        profile: creatorStore.profile,
        post,
        err: err.value,
      })
      return
    }

    // 2-2. 유저 홈 > 공지사항 리스트 > 공지사항 상세
    if (
      curParams.noticeId &&
      curParams.noticeId !== prevParams.noticeId &&
      routeName === ROUTES.CREATOR_NOTICE_DETAIL.name
    ) {
      const notice = await processByRouteName.notice.fetch({
        userSn: creatorStore.profile?.userSn || 0,
        noticeId: String(curParams.noticeId),
      })
      setSeoCaseByCase({
        profile: creatorStore.profile,
        notice,
        err: err.value,
      })
      return
    }

    // 2-3. 유저 홈 > 콜렉션 리스트 > 콜렉션 상세
    if (
      curParams.collectionId &&
      curParams.collectionId !== prevParams.collectionId &&
      routeName === ROUTES.CREATOR_COLLECTIONS_DETAIL.name
    ) {
      const collection = await processByRouteName.collection.fetch(
        String(curParams.collectionId)
      )
      setSeoCaseByCase({
        profile: creatorStore.profile,
        collection,
        err: err.value,
      })
      return
    }

    setSeoCaseByCase({
      profile: creatorStore.profile,
      err: err.value,
    })
  },
  { deep: true }
)

const onChangeTab = (id: CreatorHomeTabType) => {
  const creatorId = route.params.id
  const url = ROUTES_LOOKUP[id] || ROUTES.CREATOR.path
  useNavigations({
    url: useRoutePathIdChange(url, {
      id: String(creatorId),
    }),
  })
}

// 1. 크리에이터 조회 (Server Side)
const { data: _profile } = await useAsyncData('_profile', () =>
  processByRouteName.profile.fetch(String(route.params.id))
)

// 2. 포스트 상세 조회 (Server Side)
const { data: _post } = await useAsyncData('_post', () => {
  const fetchPostRoutes = [
    ROUTES.CREATOR_HOME_DETAIL.name,
    ROUTES.CREATOR_ACTIVITIES_DETAIL.name,
    ROUTES.CREATOR_POSTS_DETAIL.name,
  ]
  const routeName = useGetRouteName(String(route.name))

  if (fetchPostRoutes.includes(routeName)) {
    return processByRouteName.post.fetch(String(route.params.postId))
  }

  return Promise.resolve(null)
})

// 3. 공지사항 상세 조회 (Server Side)
const { data: _notice } = await useAsyncData('_notice', () => {
  const fetchNoticeRoute = [ROUTES.CREATOR_NOTICE_DETAIL.name]
  const routeName = useGetRouteName(String(route.name))

  if (_profile.value && fetchNoticeRoute.includes(routeName)) {
    return processByRouteName.notice.fetch({
      userSn: _profile.value.userSn,
      noticeId: String(route.params.noticeId),
    })
  }

  return Promise.resolve(null)
})

// 4. 마이 컬렉션 조회 (Server Side)
const { data: _collection } = await useAsyncData('_collection', () => {
  const fetchMyCollectionRoutes = [ROUTES.CREATOR_COLLECTIONS_DETAIL.name]

  const routeName = useGetRouteName(String(route.name))
  if (fetchMyCollectionRoutes.includes(routeName)) {
    return processByRouteName.collection.fetch(
      String(route.params.collectionId)
    )
  }
  return Promise.resolve(null)
})

setSeoCaseByCase({
  profile: _profile.value,
  post: _post.value,
  notice: _notice.value,
  collection: _collection.value,
  err: err.value,
})

onBeforeMount(() => {
  if (blockStatus.value === BlockStatus.BLOCKED_BY_CURRENT_USER) {
    showContents.value = false
  }
})
</script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />
    <NovaLayoutMain :page-mode="true">
      <template #center>
        <div v-if="creatorStore.profile" class="page-creator">
          <ClientOnly>
            <NovaBoxCreatorTopHeader
              :key="`${creatorStore.profile.userSn}-${locale}`"
              :tabs="tabs"
              :init-tab="tabId"
              :profile="creatorStore.profile"
              :block-status="blockStatus"
              :is-show-block-message="!!blockHeaderMessage"
              :is-show-tab-menu="showContentsObserver"
              @on-change-tab="onChangeTab"
            >
              <template v-if="blockHeaderMessage" #blockContents>
                <NovaBoxCreatorContentBlock
                  :title="blockHeaderMessage.title"
                  :message="blockHeaderMessage.message"
                  :action="blockHeaderMessage.action"
                />
              </template>
            </NovaBoxCreatorTopHeader>

            <NovaBoxCreatorContentBlock
              v-if="!showContentsObserver && blockContentsMessage"
              :title="blockContentsMessage.title"
              :message="blockContentsMessage.message"
              :action="blockContentsMessage.action"
            />
          </ClientOnly>

          <ClientOnly>
            <NuxtPage
              v-if="showContentsObserver"
              :key="creatorStore.profile.userSn"
            />
          </ClientOnly>
        </div>

        <NovaBoxError v-if="err" :error="err" :instance="true" />
      </template>
    </NovaLayoutMain>
  </NovaLayoutWrapper>
</template>

<style lang="scss" scoped>
.page-creator {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 42px;

  @include mobile {
    gap: 20px;
  }
}

@include mobile {
  .page-creator {
    padding-top: 20px;
  }
}
</style>
